import axios from 'axios'
import errorCode from '@/utils/errorCode'
import {
    getToken
} from '@/utils/auth'

import {
    Dialog,
    Notify
} from 'vant';


// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API, // 本地后台接口||服务器接口
    // 超时
    timeout: 40000
})
// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // config.headers['Authorization'] = sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    // config.headers['token'] = sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['Content-Type'] = config.type || 'application/json;charset=utf-8'
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = res.data.message || res.data.msg || errorCode['default'];
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        if (code === 401) {
            return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
        } else if (code === 402) {
            return Promise.reject(new Error(msg))
        } else if (code === 403) {
            return Promise.reject(new Error(msg))
        } else if (code === 500) {
            Notify({
                type: 'danger',
                message: msg
            });
            return Promise.reject(new Error(msg))
        } else if (code === 601) {
            Notify({
                type: 'warning',
                message: msg
            });
            return Promise.reject('error')
        } else if (code !== 200) {
            Dialog({
                title: '错误',
                message: msg
            });
            return Promise.reject('error')
        } else {
            return res.data
        }
    }, error => {
        console.log('err' + error)
        let {
            message
        } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常"; // 例如502异常
            // message = "系统更新服务中，请稍后重试";
        }
        // 弹出报错信息
        Notify({
            message: message,
            type: 'danger',
            duration: 5 * 1000,
        });
        return Promise.reject(error)
    }
)


/**
 * 使用a标签下载
 * 引入代码为 import { fileDown } from "@/utils/request.js"
 * 只能下载,通过地址就能打开的文件,公开可访问的文件
 * 例如 http://192.168.1.1/file/2023/07/24/测试文件_20230724135632A005.doc
 * 如果是向后台请求blob文件流,自己转化为文件,看utils/blobDown.js
 */
export function fileDown(blobUrl, filename) {
    const a = document.createElement("a");
    if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
    }
    a.href = blobUrl;
    a.target = "_parent";
    if ("download" in a) {
        a.download = filename;
    }
    (document.body || document.documentElement).append(a);
    a.click();
    a.remove();
}

export default service
